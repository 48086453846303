.lore-content {
  height: 100%;
  margin-top: 15%;
  justify-content: center;
}
.lore-text {
  height: 10%;
  width: 700px;
  margin: 0 auto;
  width: 70%;
  padding: 15px;
}
.lore-text h1 {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
}
.options {
  height: 250px;
  width: 700px;
  margin: auto;
  width: 70%;
}
button {
  display: flex;
  width: 250px;
  height: 50px;
  margin: 15px;
  cursor: pointer;
  background-color: rgb(233, 233, 233);
  border: none;
}
button:hover {
  background-color: white;
}
p {
  margin-top: 10px;
  text-align: left;
  font-size: 15px;
  margin-left: 15px;
}

@media (max-width: 800px) {
  .lore-text {
    width: 80%;
  }
  .options {
    margin-left: 30px;
  }
}
