/* This is the styling for the nav container */
.navbar {
  width: 100vw;
  height: 100px;
  font-family: 'DotGothic16', sans-serif;
  display: flex;
  flex-direction: column;
}
/* This is the styling for the nav content */

.navbar-content {
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
}

/* This is the styling for the left nav container */

.left-nav {
  color: #000;
  flex: 30%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.left-nav img {
  margin-top: 35px;
  width: 35px;
  height: 35px;
}
/* This is the styling for the right nav container */

.right-nav {
  flex: 70%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.right-nav a {
  color: white;
  font-size: large;
  text-decoration: none;
  font-family: 'DotGothic16', sans-serif;
  margin-left: 25px;
  margin-right: 25px;
}
