.header-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}

.header-container img {
  margin-top: 30vh;
  height: 150px;
  margin-bottom: 30vh;
}
