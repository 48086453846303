.collection-cards {
  width: 100vw;
  height: 100%;
  justify-content: center;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;
}

.collection-cards .card {
  width: 250px;
  height: 450px;
  margin: 30px;
  padding: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: white;
  background-color: black;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.349);
}
.collection-cards .card .card-title {
  height: 400px;
}
.collection-cards .card .card-body {
  height: 100px;
}
.collection-cards h1 {
  color: white;
  display: flex;
  justify-content: left;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 15px;
}
.collection-cards p {
  color: white;
  margin-top: 0px;
}
.collection-cards button {
  background-color: white;
  color: black;
  border: none;
  height: 20px;
  font-size: large;
}
.collection-cards img:hover {
  background-color: black;
  color: white;
  border: none;
  height: 50px;
  opacity: 0.8;
}
.card .card-art img {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  height: 250px;
  width: 250px;
  position: relative;
}
.card .card-art:hover {
  opacity: 0.8;
}
.card .card-links {
  height: 25px;
  margin-top: 25px;
  margin-left: 0px;
}
.card .card-links img {
  height: 100%;
  padding-left: 15px;
  padding-right: 10px;
}
@media only screen and (max-width: 1000px) {
  .collection-cards {
    display: grid;
  }
}
