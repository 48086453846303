.footer {
  width: 100vw;
  min-height: 100px;
  height: 100px;
  margin: 0;
  background: #000000;
  text-align: center;
  align-content: center;
  margin-bottom: 0px;
  padding-top: 20px;
}
.social-links img {
  height: 20px;
  margin: 20px;
  cursor: pointer;
  align-items: center;
  padding-left: 15px;
}

.footer p {
  margin-top: -5px;
  color: white;
  font-size: 12px;
  text-align: center;
}
