.sacrafice-container {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 80vw;
  height: 100%;
  display: flex;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}
.sacrafice-container .sacrafice-content {
  display: grid;
  flex-wrap: wrap;
  flex-direction: column;
  width: 150px;
  align-items: left;
}
.flier {
 

  height: 150px;
  width: 150px;
  margin: 0px;
}
.sacrafice-content h1 {
  margin-left: 15px;
  margin-top: 15%;
  margin-bottom: 0px;
  color: white;
  text-align: left;
}
.sacrafice-content p {
  color: white;
  text-align: left;
  margin-top: 15px;

  margin-bottom: 0px;
}
.sacrafice-content button {
  width: 150px;
  height: 40px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.801);
  color: black;
  font-size: 16px;
}
.sacrafice-content button:hover {
  cursor: pointer;
  background-color: white;
}
.sacrafice-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(0, 0, 0);
}
