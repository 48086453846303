@import url("https://fonts.googleapis.com/css2?family=DotGothic16&family=Press+Start+2P&display=swap");

.App {
  text-align: center;
}

body {
  padding: 0px;
  margin: 0px;
  background-color: black;
}

h1,
h2,
p {
  font-family: "DotGothic16", sans-serif;
}

.unity-canvas {
  width: 100%;
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.NA {
  height: 80vh;
}
.NA h1 {
  padding-top: 30vh;
  text-align: center;
  font-size: 24px;
}
